import { createApp } from 'vue';
import * as Vue from 'vue';
import i18next from "i18next";
import I18NextVue from 'i18next-vue';
import Backend from 'i18next-xhr-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import Notifications from '@kyvg/vue3-notification'
import router from "./router";
import App from "./App.vue";
import bg from "./locales/bg.js";
import en from "./locales/en.js";
import { createStore } from 'vuex'
import Loading from './components/Loading.vue';
import Footer from './components/Footer.vue';
import UrboFooter from './components/FooterUrboStudio.vue';
import UrboPaymentsFooter from './components/FooterPaymentMethods.vue';
import './css/index.css'
import '@fortawesome/fontawesome-pro/js/light.min.js'
import '@fortawesome/fontawesome-pro/js/thin.min.js'
import '@fortawesome/fontawesome-pro/js/solid.min.js'
import '@fortawesome/fontawesome-pro/js/duotone.min.js'
import '@fortawesome/fontawesome-pro/js/sharp-regular.min.js'
import './api.js'

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

const store = createStore({
	state () {
	  return {
		auth: {}
	  }
	},
	mutations: {
  
	}
});

i18next
  .use(Backend)
  .use(LanguageDetector)
  .init({
    lng : "bg",
    resources: {
      "bg": bg,
      "en": en
    },
    debug: false, // Set to false in production
    // backend: {
    //   backends: [
    //     HttpBackend,
    //     resourcesToBackend(bundledResources)
    //     // resourcesToBackend((lng, ns) => import(`../src/locales/${lng}/${ns}.json`))
    //   ],
    //   backendOptions: [{
    //     loadPath: '../src/locales/en.json'
    //   }]
    // },
    fallbackLng: 'en', // Default language
    interpolation: {
      escapeValue: false, // React already escapes data
    },
});


const app = createApp(App)
app.use(I18NextVue, { i18next });
app.use(router)
app.use(Notifications)
app.use(store)
app.component('font-awesome-icon', FontAwesomeIcon)
app.component('loading', Loading)
app.component('footer-paymentMethods', UrboPaymentsFooter)
app.component('footer-component', Footer)
app.component('footer-urboStudio', UrboFooter)
app.mount('#app')