<template>
  <div id="app">
    <notifications position="top right" />
    <router-view />
  </div>
</template>

<script>

import { useFavicon } from '@vueuse/core'
export default {
  name: 'App',
  mounted(){
    const source = 'urbo-city-favicon.png'
    const icon = useFavicon(source)
  }
}
</script>
