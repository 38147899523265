<template>
    <div class="flex flex-row items-center md:w-1/3 sm:w-1/2 mx-auto justify-center mt-3">
      <span class="font-semibold text-xs">{{ $t('partnership-with') }}</span>
      <img src="../../assets/images/visa_logo.png" class="w-2/12" id="visaLogo" alt="Visa logo" />
      <img v-if="lang == 'bg'" src="../../assets/images/dsk_bg_logo.png" class="w-3/12 ml-3" alt="DSK bank logo" />
      <img v-else src="../../assets/images/dsk_logo.png" class="w-3/12 ml-3" alt="DSK bank logo" />
    </div>
</template>

<script>
export default {
    props: {
        lang: String
    },
    mounted() {
        var space = document.getElementById("visaLogo").width * 0.25;
        document.getElementById("visaLogo").style.marginRight = space + "px";
        document.getElementById("visaLogo").style.marginLeft = space + "px";
    }
};
</script>