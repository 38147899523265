import * as Vue from 'vue';
import * as VueRouter from 'vue-router';

const All = () => import("./components/HelloWorld.vue");
const PrivateParking = () => import("./components/PrivateParking.vue");
const PublicParking = () => import("./components/PublicParking.vue");
const Clamps = () => import("./components/Clamps.vue");
const Events = () => import("./components/Events.vue");
const CheckPayment = () => import("./components/CheckPayment.vue");
const DEFAULT_TITLE = 'QR Payment software';

const router = VueRouter.createRouter({
    history: VueRouter.createWebHashHistory(),
    // history: VueRouter.createWebHistory(),
    // mode: 'history',
    routes: [
        {
            path: '/pp/:hash',
            component: PrivateParking
        },
        {
            path: '/zp/:hash',
            component: PublicParking
        },
        {
            path: '/cl/:hash',
            component: Clamps
        },
        {
            path: '/ev/:hash',
            component: Events
        },
        {
            path: '/payment/:type/:orderId/:response?',
            component: CheckPayment
        },
        {
            path: '/',
            component: All
        },
    ]
});

// router.beforeEach((to,from,next) => {
//     if (to.fullPath == '/' || to.fullPath == '/pp' || to.fullPath == '/pp/') {
//         next('pp/34286c0fe2fe5c76e24d9cfa7d6990ac')
//     }else{
//         next()
//     }
// });
router.afterEach((to) => {
    Vue.nextTick(() => {
        document.title = to.meta.title || DEFAULT_TITLE;
    });
});

export default router;