var dev = false;
// if (window.location.hostname != 'qr.urboapp.com') {
//     dev = true;
// }
if (window.location.hostname.includes('dev-')) {
    dev = true;
}

if (window.location.hostname.includes('uat-') || window.location.hostname.includes('localhost')){
    dev = 'uat';
}

var parkingApiUrl = "";
var customerId = "";
var URBOBANK = "";
var token = "";
var CLAMP_CHECK = "";
var CLAMP_REMOVE = "";
var CL_TOKEN = "";
var urboApi = '';
var urboApiToken = '';

if(dev == 'uat'){
    parkingApiUrl = 'https://facade.urboapp.com/urbo-core';
    customerId = 17113;
    token = 'Z3Vlc3RAdXJib2FwcC5jb20vMTpnOUF6SjZhNSphc0BTMVAzdyooUCxucWJRNDdrLzhJWw==';
    URBOBANK = 'https://mdpay-test.fibank.bg/ecomm_v2/ClientHandler?trans_id='; //FIBANK
    // URBOBANK = 'https://uat.dskbank.bg/payment/merchants/payment_en.html?mdOrder='; //DSK
    CLAMP_CHECK = 'http://213.16.35.4:8120/clamp-remove-service/rest/checkClamp';
    CLAMP_REMOVE = 'http://213.16.35.4:8120/clamp-remove-service/rest/removeClampMessageUrbo';
    CL_TOKEN = 'dXJibzp1cmJvMjAyMA==';
    urboApi = 'https://uat-api.urboapp.com/api';
    urboApiToken = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsImp0aSI6IjQzNTQxZTQzODMwYzI3NjcxNzk3YzE3N2MzN2U4OTVmZTM2ZDAwYWEzYzk2MjE1YmZkYWMxMzY5MTQ4Y2NlYzMyNGEyYzU0MDUzNWE1NDBkIn0.eyJhdWQiOiIxIiwianRpIjoiNDM1NDFlNDM4MzBjMjc2NzE3OTdjMTc3YzM3ZTg5NWZlMzZkMDBhYTNjOTYyMTViZmRhYzEzNjkxNDhjY2VjMzI0YTJjNTQwNTM1YTU0MGQiLCJpYXQiOjE3MzIxNzUyMjQsIm5iZiI6MTczMjE3NTIyNCwiZXhwIjoxNzYzNzExMjIzLCJzdWIiOiIyIiwic2NvcGVzIjpbXX0.IsXVQkLZ4DWNC_WcYj7dJVoSscHaiu64bnYgCtLc8nvK7RjcAYdWmHIFf92TpMEPLejtK_FtVtkIgrcKu0Smq-hrLXU-y_7EkrIbvymC0tGOTYdhiCvt35B8wTIqQTK8DLYmFjFQJN8TELsbdFkBI7kI1oYj_PTYqe4qMl3tBbNaTYwf0v2OfD5PvrdmICAE5fRKA_aqsvV_od8OWZfhxABTEpGK-SrVL81etSdViMwRJtgQ10QLjXMCzOPJsQmSZUdLfiutKdOfApU9GSCTTfrfSHS_OUhDwGc433st0qh-yDT2XEWHTkAgOPf8nqEXd3dWBrSk83s7XWvKaTBYHR94fRiKzfOFt01mLsKjvNeduIL4cbafqN3gCokMtS-P3Kz17rgSHR6_60CYMNLSsBmFvT168fpxNZWRIDpCeYmLtivcSlTSDF1gxYXb-M8fPsNigu65xINxdj_LND5kBCkJGrwS1Eh07ErOG4ULtn1r3i_5FD1RUCUidLhuwhZ0-XaZWlmyM007sXAdkMk4Bn27clmxibYKyRM0PSP5uKCB202UDSM0qcdnv40i1ChAYLo9rNDffRSrfSU-sdx61ClvsH4SKyqZ19EiiW4jEjfQnZZSlR9VIT4ZgH4Z5eLdV8-qRqEB7ZEA4SJO5AVMv25AMTVX96xcBhBIRxCyuMs';
} else if(dev == true){
    parkingApiUrl = 'https://facade.urboapp.com/urbo-core';
    customerId = 17113;
    token = 'Z3Vlc3RAdXJib2FwcC5jb20vMTpnOUF6SjZhNSphc0BTMVAzdyooUCxucWJRNDdrLzhJWw==';
    URBOBANK = 'https://mdpay-test.fibank.bg/ecomm_v2/ClientHandler?trans_id='; //FIBANK
    // URBOBANK = 'https://uat.dskbank.bg/payment/merchants/payment_en.html?mdOrder='; //DSK
    CLAMP_CHECK = 'http://213.16.35.4:8120/clamp-remove-service/rest/checkClamp';
    CLAMP_REMOVE = 'http://213.16.35.4:8120/clamp-remove-service/rest/removeClampMessageUrbo';
    CL_TOKEN = 'dXJibzp1cmJvMjAyMA==';
    urboApi = 'https://2721n6fvol.execute-api.eu-west-1.amazonaws.com/dev/uapi';
    urboApiToken = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsImp0aSI6ImJjZmMyOTdkMjJmYjFmYjNmZjI4ZDQ2MThjYjk4YmJlOWVjYWNiZjM1ZGY2MzFjNzhkZTYyNjNiYTRmZDhhZWI5YWYwZTcxMWVhZjczZmM4In0.eyJhdWQiOiIxIiwianRpIjoiYmNmYzI5N2QyMmZiMWZiM2ZmMjhkNDYxOGNiOThiYmU5ZWNhY2JmMzVkZjYzMWM3OGRlNjI2M2JhNGZkOGFlYjlhZjBlNzExZWFmNzNmYzgiLCJpYXQiOjE3MTIyMzU4NzgsIm5iZiI6MTcxMjIzNTg3OCwiZXhwIjoxNzQzNzcxODc4LCJzdWIiOiIyIiwic2NvcGVzIjpbXX0.Lvy1Bpz2nlykFHjAdV4sJDtHu58MpqVplXrqXdPKhtw_wG7tCdT_5w56-mrDK97GBqthtxAyfwjFbccPtUmsNCxpA032xpawFUsC3N2WzsufvjK9BjpDMkFqfTE-GRUTRbwTLGlv5SDeqyaqFgHEg40YeTyY3y3_0qNchEz2HcZEzG7K-tEi7adBHle98mvhARG_Gd2WknfTkTt8PpGZ9HxqwFy7MCmSO1Yz6gkXpWBW_mydgs3RehQIjfUhp1_wgOvZorhwC0p5bXNIWGdTZTAXXpW34JsJp0n_co2mU-mvc3stnTfvzAsJR_2ssbI5SDc17VyxNlyV2KE6o1Xk9BgzPuvoIcTzh4Q41HWsvIYl0vW2RJYO3ETD_P9YK1xt0NQHHmZqIScYYEWoH03gf9505UMXAmPjR-7rWjan_NIjIiZPsrpRwhbZ_B-wveVmXzj2eWO5gWF2c4oTd4YsnqCyM1VG0CbnNQG6EF07Io7wH5IVFpfEI6zgAKtrLaB2nQkottP6JLLdA7PC-3yFqfRA13N9lFOXCYRUUHBgWOyHfgQ9eam1WgmrzcaVbQzb5kSOhfP92g_CZeaoWx0lkTUI1sd1w7gRtdO9UbCQKN09x-8THENNhP5IFgTWAFfeC6GSoPogI7rvQMYSbalTHO5KCrhugZrGcNipfvG0ZeI';
}else{
    parkingApiUrl = 'https://production.urboapp.com/urbo-core';
    customerId = 108435;
    token = 'Z3Vlc3RAdXJib2FwcC5jb20vMTpnOUF6SjZhNSphc0BTMVAzdyooUCxucWJRNDdrLzhJWw==';
    URBOBANK = 'https://mdpay.fibank.bg/ecomm_v2/ClientHandler?trans_id=';   //FIBANK
    CLAMP_CHECK = 'https://clamp-service.sofiatraffic.bg/api/check';
    CLAMP_REMOVE = 'https://clamp-service.sofiatraffic.bg/api/remove-request';
    CL_TOKEN = 'dXJibzp1cmJvMjAyMA==';
    urboApi = 'https://api.urboapp.com/api';
    urboApiToken = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsImp0aSI6IjRhNmMyZTExYzg4MTZjODNmZDNlYjIwODdlZjlmMzFjZTE5NjMyM2M5YTYxODMyZTAxOTAyZDljN2FlM2NkYmVmODA0ZTgwMmI1ZjcxMzcwIn0.eyJhdWQiOiIxIiwianRpIjoiNGE2YzJlMTFjODgxNmM4M2ZkM2ViMjA4N2VmOWYzMWNlMTk2MzIzYzlhNjE4MzJlMDE5MDJkOWM3YWUzY2RiZWY4MDRlODAyYjVmNzEzNzAiLCJpYXQiOjE3MjQ2NTk4MzYsIm5iZiI6MTcyNDY1OTgzNiwiZXhwIjoxNzU2MTk1ODM2LCJzdWIiOiIyIiwic2NvcGVzIjpbXX0.Y0KuFscNrzogJU32nyLyFUclZNvNrhnJl8HNyk8FNO3DCszw1GFEBw4RVGR5Yhj4kFLLRWlKvdn01KQWKpVlSJyAuRRAYei8PJesygCgzM0osv7RSsb80e_i2t1ain8P6DmgV6ebvYeVIniO7vrZuGpSuotkFzsShAtZsL9Wp9TM5w0LoLZ_WsqcOw2dgLp0fnG1i3RBgxTjdfM0lIFVWQSs5HNuEbjLzqkQtdJAztT3puuceoDgirxzXJM-0XkVaS0HSKwiTKG6QWN981je0RKSlaNy5ilxITcnkv7mVEsQFH9A8EvZnpXVCQ3PdHxYfkl8cv590c5zB_r7A4CfkIz0TBHUckyaK8lWbhTxS31qDr4ewMqdxCfY3QEq8SgLrSAF-3BBjFaXTxtZ0cFjjyPJYaV_m93vg8QDx_1s6R0nCkiPMwrLqEK3hXS5fPdkrSkSqD07Xz4XBzuCp5IsgB9CNJDPriDNXelWmn4KKddA5CQKv9OH0TmCu3EU23g3H_8YifaxuciNmPFGZ31Tnx972ZWTJSWjutraXGAvzT7lyZsiW9d34jIcsqRFcm5PJnGCkeYdZFaG4Z7xbUka8XoZ15hkbs-1NUnOvuuM0bdxoPKH--dbTStaiOVQ4a8zZUi1PvcN5HgVpZeSMwDZ4Hos-D-iCh868iPWJGRmzG4'
}

export const PARTNERID = 1;
export const CUSTOMERID = customerId;
export const TOKEN = token;
export const URBO_FACADE_URL_API = parkingApiUrl;
export const BANKURL = URBOBANK;
export const BANK = 'DSK'
export const CLAMP_CHECK_URL = CLAMP_CHECK;
export const CLAMP_REMOVE_URL = CLAMP_REMOVE;
export const CLAMP_TOKEN = CL_TOKEN;
export const URBO_API = urboApi;
export const URBO_API_TOKEN = urboApiToken;