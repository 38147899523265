import axios from 'axios'
import { URBO_FACADE_URL_API, CUSTOMERID, TOKEN, CLAMP_REMOVE_URL, CLAMP_TOKEN, URBO_API, URBO_API_TOKEN } from './globals'
import { notify } from "@kyvg/vue3-notification";

const config = {
    headers: { 
        'Content-Type': 'application/json',
        Authorization: `Basic ${TOKEN}`
             }
};

const configUrbo = {
    headers: { 
        'Content-Type': 'application/json',
        Authorization: `Bearer ${URBO_API_TOKEN}`}
};

export function checkCarNumberParking(carNumber, zone){
    var req = axios.post(`${URBO_FACADE_URL_API}/api/m/serviceorders/getticketbycarnumber?customerId=${CUSTOMERID}&carNumber=${carNumber}&zone=${zone}`,
        {}, 
        config)
        .then(res => {return res.data})
        .catch(err => {return err})
    
    return req
}

export function makeOrder(body, documentId = false){
    var url = `${URBO_FACADE_URL_API}/api/m/serviceorders/makeOrder?customerId=${CUSTOMERID}`;
    if (documentId) {
        url += `&extendDocumentId=${documentId}`;
    }
    var req = axios.post(url,
        body, 
        config)
        .then(res => {return res.data})
        .catch(err => {throw err.response.data})
    
    return req
}

export function checkTransactionStatus(transId){
    var req = axios.get(`${URBO_FACADE_URL_API}/api/m/serviceorders/checkTransactionStatus?transId=${transId}`,
        config)
        .then(res => {return res.data})
        .catch(err => {return err})
    
    return req
}

export function checkPayment(orderId){
    var req = axios.get(`${URBO_FACADE_URL_API}/api/m/serviceorders/checkOrder?orderId=${orderId}`,
        config)
        .then(res => {return res.data})
        .catch(err => {return err})
    
    return req
}

export function payPrivateParking(totalSum, orderId, carNumber, checkRequestDTime){
    var req = axios.post(`${URBO_FACADE_URL_API}/api/m/serviceorders/payticketbycarnumber?customerId=${CUSTOMERID}&carNumber=${carNumber}&totalSum=${totalSum}&orderId=${orderId}&checkRequestDTime=${checkRequestDTime}`,
        {},
        config)
        .then(res => {return res.data})
        .catch(err => {return err})
    
    return req
}

export function getPrivateParking(id){
    var req = axios.get(`${URBO_FACADE_URL_API}/api/m/areas/getallpins?id=${id}`,
        config)
        .then(res => {return res.data})
        .catch(err => {return err})
    
    return req
}

export function getPublicParking(areaId){
    var req = axios.get(`${URBO_FACADE_URL_API}/api/m/products?area=${areaId}&type=PARK`,
        config)
        .then(res => {return res.data})
        .catch(err => {return err})
    
    return req
}

export function getZoneData(id){
    var req = axios.get(`${URBO_FACADE_URL_API}/api/m/products/single/${id}`)
        .then(res => {return res.data})
        .catch(err => {return err})
    
    return req
}

export function getActiveParkings(orderId){
    var req = axios.get(`${URBO_FACADE_URL_API}/api/m/customers/${CUSTOMERID}/extendParkingInfo?orderId=${orderId}`,
        config)
        .then(res => {return res.data})
        .catch(err => {return err})
    
    return req
}

export function checkActiveParking(carNumber, product){
    var req = axios.post(`${URBO_FACADE_URL_API}/api/m/serviceorders/carOrders?customerId=${CUSTOMERID}&carNumber=${carNumber}&productId=${product}`,
        {},
        config)
        .then(res => {return res.data})
        .catch(err => {return err})
    
    return req
}

export function checkClamp(carNumber, productId){
    var req = axios.post(`${URBO_FACADE_URL_API}/api/m/serviceorders/checkClamp?customerId=${CUSTOMERID}&carNumber=${carNumber}&productId=${productId}`,
        {},
        config)
        .then(res => {return res.data})
        .catch(err => {return err})
    
    return req
}

export function getGeolocation(lat, lon){
    var req = axios.get(`${URBO_FACADE_URL_API}/api/m/serviceorders/reverseGeocode?lat=${lat}&lon=${lon}`,
        // {},
        config)
        .then(res => {return res.data})
        .catch(err => {return err})
    
    return req
}

export function getEventData(eventProgramId, lang){
    var req = axios.post(`${URBO_API}/events/get`,
        {
            id: eventProgramId,
            lang: lang
        },
        configUrbo)
        .then(res => {return res.data})
        .catch(err => {return err})
    
    return req
}

export function getEventPrograms(eventId, lang){
    var req = axios.post(`${URBO_API}/events/get`,
        {
            event_id: eventId,
            lang: lang,
            order: 'asc',
            take: 5
        },
        configUrbo)
        .then(res => {return res.data})
        .catch(err => {return err})
    
    return req
}

export function getHallSeats(eventProgramId, hallId, lang){
    var req = axios.post(`${URBO_API}/events/getSeats`,
        {
            event_program_id: eventProgramId,
            hall_id: hallId,
            lang: lang
        },
        configUrbo)
        .then(res => {return res.data})
        .catch(err => {return err})
    
    return req
}

export function getTicketTypes(eventProgramId, hallId, lang){
    var req = axios.post(`${URBO_API}/events/getAvailableTicketTypes`,
        {
            event_program_id: eventProgramId,
            lang: lang
        },
        configUrbo)
        .then(res => {return res.data})
        .catch(err => {return err})
    
    return req
}

export function getEventAncillaries(eventId, lang){
    var req = axios.post(`${URBO_API}/events/getEventAncillaries`,
        {
            event_id: eventId,
            lang: lang
        },
        configUrbo)
        .then(res => {return res.data})
        .catch(err => {return err})
    
    return req
}

export function getEmailTemplate(eventProgramId, lang){
    var req = axios.post(`${URBO_API}/events/getEventEmailTemplate`,
        {
            event_program_id: eventProgramId,
            lang: lang
        },
        configUrbo)
        .then(res => {return res.data})
        .catch(err => {return err})
    
    return req
}

export function reservationAdd(body){
    var req = axios.post(`${URBO_API}/reservations/add`,
        body,
        configUrbo)
        .then(res => {return res.data})
        .catch(err => {return err})
    
    return req
}

export function reservationAddWithoutScheme(body){
    var req = axios.post(`${URBO_API}/events/createReservationWithoutScheme`,
        body,
        configUrbo)
        .then(res => {return res.data})
        .catch(err => {return err})
    
    return req
}

export function getPaymentMethods(placeId, whitelabelId){
    var req = axios.post(`${URBO_API}/payments/getAll`,
        {
            place_id: placeId,
            whitelabel_id: whitelabelId
        },
        configUrbo)
        .then(res => {return res.data})
        .catch(err => {return err})
    
    return req
}

export function hostUserSeats(body){
    var req = axios.post(`${URBO_API}/events/createReservationWithoutSchemeConfirmation`,
        body,
        configUrbo)
        .then(res => {return res.data})
        .catch(err => {return err})
    
    return req
}